import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./home.css";
import { Col, Row, Container } from "react-bootstrap";
import he from "he";
import axios from "axios";
import Footer from "../footer/Footer";
import Loader from "../loader/Loader";
import { limitContent } from "../utils";

const Home = () => {
  const [pages, setPages] = useState([]);
  const [featuredImage, setFeaturedImage] = useState("");
  const [theAcf, setAcf] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://paroledecrabe.mathistogni.fr/wp-json/wp/v2/posts?type=page"
        );

        const responseImage = await axios.get(
          "https://paroledecrabe.mathistogni.fr/wp-json/wp/v2/media/432"
        );
        const responseAcf = await axios.get(
          "https://paroledecrabe.mathistogni.fr/wp-json/acf/v3/pages/51"
        );
        setPages(response.data);
        setFeaturedImage(responseImage.data.source_url);
        setAcf(responseAcf.data.acf.statistics);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Mettre à jour l'état "loading" lorsque la requête est terminée
      }
    };
    fetchData();
  }, []);

  const [posts, setPosts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://paroledecrabe.mathistogni.fr/wp-json/wp/v2/posts?_embed&per_page=3"
        );
        setPosts(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <Loader loading={loading} />
      <header
        className="header headroom"
        style={{ backgroundImage: `url(${featuredImage})` }}
      >
        <img
          width="558"
          height="274"
          src="https://paroledecrabe.mathistogni.fr/wp-content/uploads/2022/01/logo-e1643216756278.png"
          className="attachment-large size-large"
          alt=""
          decoding="async"
          loading="lazy"
          srcSet="https://paroledecrabe.mathistogni.fr/wp-content/uploads/2022/01/logo-e1643216756278.png 558w, https://paroledecrabe.mathistogni.fr/wp-content/uploads/2022/01/logo-e1643216756278-300x147.png 300w, https://paroledecrabe.mathistogni.fr/wp-content/uploads/2022/01/logo-e1643216756278-150x74.png 150w"
          sizes="(max-width: 558px) 100vw, 558px"
        />
        <div className="greenButton">
          <a
            href="https://don.ligue-cancer.net/lamourlaligue/~mon-don"
            target="_blank"
            rel="noreferrer"
          >
            FAIRE UN DON
          </a>
        </div>
      </header>
      <div className="container">
        <div className="green my-5">
          <h2 className="black">Le cancer en France</h2>
        </div>
        <h3 className="my-5">
          Près de <span className="highlighting">382 000</span> nouveaux cas en
          2018
        </h3>
        <div className="row stat">
          {theAcf.map((acfChamp, index) => (
            <div className="col-md-4" key={index}>
              <img src={acfChamp.img} alt="" className="card-img-top" />
              <h4
                dangerouslySetInnerHTML={{
                  __html: acfChamp.number,
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: acfChamp.sex,
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="actu">
        <Container className="py-4">
          <div className="purple my-5">
            <h2 className="white">Nos derniers articles</h2>
          </div>
          <Row>
            {pages.slice(0, 3).map((page) => (
              <Col md={4} className="mb-3 mt-2" key={page.id}>
                <article className="card">
                  {posts
                    .filter((post) => post.id === page.id)
                    .map((post, index) => {
                      const featuredMedia =
                        post._embedded && post._embedded["wp:featuredmedia"]
                          ? post._embedded["wp:featuredmedia"][0]
                          : null;

                      if (featuredMedia) {
                        return (
                          <img
                            src={
                              featuredMedia.media_details.sizes.full.source_url
                            }
                            alt=""
                            key={index}
                            className="card-img-top"
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  <div className="card-body">
                    <div className="textZone">
                      <h5
                        className="card-title mb-4"
                        dangerouslySetInnerHTML={{
                          __html: page.title.rendered,
                        }}
                      />
                      <p className="card__excerpt mb-4">
                        {limitContent(
                          he.decode(
                            page.content.rendered.replace(/<[^>]+>/g, "")
                          )
                        )}
                      </p>
                    </div>
                    <Link to={`/post/${page.id}`} className="btnPurple">
                      lire la suite
                    </Link>
                  </div>
                </article>
              </Col>
            ))}
          </Row>
          <div className="text-center my-5">
            <a className="greenBtn" href="/News">
              VOIR PLUS
            </a>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
