import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./navigation.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const Navigation = () => {
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("token"));
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    setLoggedIn(false);
    navigate("/login");
  };

  return (
    <Navbar
      className="navbar-expand-lg navbar-light fixed-top"
      bg="light"
      expand="lg"
    >
      <Container>
        <Navbar.Brand href="/">PAROLE DE CRABE</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav style={{ marginLeft: "auto" }}>
            <Nav.Link href="/">ACCUEIL</Nav.Link>
            <div className="space"></div>
            <Nav.Link href="/News">S'INFORMER</Nav.Link>
            <div className="space"></div>
            {loggedIn ? (
              <>
                <Nav.Link className="logout" onClick={handleLogout}>
                  DÉCONNEXION
                </Nav.Link>
                <div className="space"></div>
                <Nav.Link className="admin" href="/admin">
                  MON COMPTE
                </Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link className="connexion" href="/Login">
                  SE CONNECTER
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
