import React from "react";

const Loader = ({ loading }) => {
  return (
    <div className={`loader-container ${loading ? "" : "hide"}`}>
      <div className="loader" />
    </div>
  );
};

export default Loader;
