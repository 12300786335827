import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../loader/Loader";
import Footer from "../footer/Footer";
import "./post.css";

const Post = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [imgFeaturedUrl, setImgFeaturedUrl] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://paroledecrabe.mathistogni.fr/wp-json/wp/v2/posts/${id}?_embed`
        );
        const responseImgFeatured = await axios.get(
          `https://paroledecrabe.mathistogni.fr/wp-json/wp/v2/media/${response.data.featured_media}`
        );
        setPost(response.data);
        setImgFeaturedUrl(responseImgFeatured.data.source_url);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Mettre à jour l'état "loading" lorsque la requête est terminée
      }
    };
    fetchData();
    // Faire défiler vers le haut de la page lorsque la page est chargée
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <div>
      <Container className="Post py-5 my-5">
        <Loader loading={loading} />
        <div className="green my-5">
          <h1
            className="black"
            dangerouslySetInnerHTML={{ __html: post && post.title.rendered }}
          ></h1>
        </div>
        <div className="imgFeatured">
          {imgFeaturedUrl && (
            <img src={imgFeaturedUrl} alt={post && post.title.rendered} />
          )}
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: post && post.content.rendered }}
        ></div>
      </Container>
      <Footer />
    </div>
  );
};

export default Post;
