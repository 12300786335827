import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./footer.css";
const Footer = () => {
  const [acfCol1, setAcfCol1] = useState([]);
  const [acfCol2, setAcfCol2] = useState([]);
  const location = useLocation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseAcfCol1 = await axios.get(
          "https://paroledecrabe.mathistogni.fr/wp-json/acf/v3/options/infos"
        );
        const responseAcfCol2 = await axios.get(
          "https://paroledecrabe.mathistogni.fr/wp-json/acf/v3/options/infos"
        );
        setAcfCol1(
          responseAcfCol1.data.acf.group_social_media.repeater_social_media
        );
        setAcfCol2(
          responseAcfCol2.data.acf.group_partners.repeater_img_partner
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <footer className="container">
      {location.pathname !== "/" && <hr />}
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-5 footer_items">
          <p className="title_footer_link">NOS RÉSEAUX SOCIAUX</p>
          <div>
            {acfCol1.map((link, index) => (
              <a href={link.link_social_media} key={index}>
                <img src={link.img_social_media} alt="#" />
              </a>
            ))}
          </div>
        </div>
        <div className="col-md-5 footer_items">
          <p className="title_footer_link">NOS PARTENAIRES</p>
          <div>
            {acfCol2.map((b, index) => (
              <a href={b.link_partner} key={index}>
                <img src={b.img_partner} alt="" />
              </a>
            ))}
          </div>
        </div>
        <div className="col-md-1"></div>
      </div>
      <p className="pt-5">© Ligue nationale contre le cancer 2020 </p>
    </footer>
  );
};

export default Footer;
