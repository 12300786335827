import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useNavigate } from "react-router-dom";
import "./session.css";

const Admin = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [newUsername, setNewUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      axios
        .get("https://paroledecrabe.mathistogni.fr/wp-json/wp/v2/users/me", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setUser(response.data);
        })
        .catch(() => {
          navigate("/login");
        });
    }
  }, [navigate]);

  const handleUsernameChange = (event) => {
    setNewUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleSaveUsernameClick = () => {
    const token = localStorage.getItem("token");
    axios
      .put(
        "https://paroledecrabe.mathistogni.fr/wp-json/wp/v2/users/me",
        { name: newUsername },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSavePasswordClick = () => {
    const token = localStorage.getItem("token");
    if (newPassword === newPasswordConfirm) {
      axios
        .put(
          "https://paroledecrabe.mathistogni.fr/wp-json/wp/v2/users/me",
          { password: newPassword },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      alert("Les mots de passe ne correspondent pas.");
    }
  };

  return (
    <div>
      {user ? (
        <div className="container py-5 my-5">
          <h1>
            Bienvenue <span className="adminUser">{user.name}</span> !
          </h1>
          <div className="py-5">
            <label htmlFor="">PSEUDO</label>

            <InputGroup
              className="mb-3"
              value={newUsername}
              onChange={handleUsernameChange}
            >
              <Form.Control
                aria-label="Example text with button addon"
                aria-describedby="basic-addon1"
              />
            </InputGroup>

            <Button
              variant="outline-secondary"
              id="button-addon1"
              onClick={handleSaveUsernameClick}
              disabled={!newUsername}
            >
              ENREGISTRER NOUVEAU PSEUDO
            </Button>

            <div className="py-5">
              <label htmlFor="">MOT DE PASSE</label>
              <InputGroup // Ajout du champ pour le nouveau mot de passe
                className="mb-3"
                value={newPassword}
                onChange={handlePasswordChange}
              >
                <Form.Control
                  type="password"
                  aria-label="Example text with button addon"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              <label htmlFor="">CONFIRMER LE MOT DE PASSE</label>
              <InputGroup
                className="mb-3"
                value={newPasswordConfirm}
                onChange={(event) => setNewPasswordConfirm(event.target.value)}
              >
                <Form.Control
                  type="password"
                  aria-label="Example text with button addon"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              <Button
                variant="outline-secondary"
                id="button-addon1"
                onClick={handleSavePasswordClick}
                disabled={!newPassword} // Modification pour désactiver le bouton si un des champs est vide
              >
                ENREGISTRER NOUVEAU MOT DE PASSE
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="container py-5 my-5">Chargement en cours...</div>
      )}
    </div>
  );
};

export default Admin;
