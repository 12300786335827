import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./news.css";
import { Col, Row, Container, Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import he from "he";
import { limitContent } from "../utils";
import Loader from "../loader/Loader";
import Footer from "../footer/Footer";
const News = () => {
  const [pages, setPages] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [numPosts, setNumPosts] = useState(9);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [postsResponse, pagesResponse] = await Promise.all([
          axios.get(
            `https://paroledecrabe.mathistogni.fr/wp-json/wp/v2/posts?_embed&per_page=${numPosts}`
          ),
          axios.get(
            `https://paroledecrabe.mathistogni.fr/wp-json/wp/v2/posts?type=page=${numPosts}`
          ),
        ]);
        setPosts(postsResponse.data);
        setPages(pagesResponse.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [numPosts]);

  const [categ, setCatef] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseCateg = await axios.get(
          "https://paroledecrabe.mathistogni.fr/wp-json/wp/v2/categories"
        );
        setCatef(responseCateg.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleShowMore = () => {
    setNumPosts(numPosts + 6);
  };

  return (
    <div className="news">
      <Loader loading={loading} />
      <Container className="pt-5 my-5">
        <div className="purple my-5">
          <h2 className="black">Nos derniers articles</h2>
        </div>
        <Dropdown className="mb-4">
          <Dropdown.Toggle className="btn btn-primary" id="dropdown-basic">
            FILTRER
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {categ.map((a, index) => {
              if (a.id !== 1) {
                return (
                  <Dropdown.Item
                    className={a.id === selectedCategory ? "selected" : ""}
                    key={index}
                    onClick={() => setSelectedCategory(a.id)}
                    id={a.id}
                  >
                    {a.name}
                  </Dropdown.Item>
                );
              }
              return null;
            })}
          </Dropdown.Menu>
        </Dropdown>

        <Row>
          {posts
            .filter((post) =>
              selectedCategory
                ? post.categories.includes(selectedCategory)
                : true
            )
            .map((post) => (
              <Col md={4} className="col-md-4 mb-3 mt-2" key={post.id}>
                <article className={`card ${post.categories}`}>
                  <img
                    src={
                      post._embedded["wp:featuredmedia"][0].media_details.sizes
                        .full.source_url
                    }
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div className="textZone">
                      <h5
                        className="card-title mb-4"
                        dangerouslySetInnerHTML={{
                          __html: post.title.rendered,
                        }}
                      />
                      <p className="card__excerpt mb-4">
                        {limitContent(
                          he.decode(
                            post.content.rendered.replace(/<[^>]+>/g, "")
                          )
                        )}
                      </p>
                    </div>

                    <Link to={`/post/${post.id}`} className="btnPurple">
                      lire la suite
                    </Link>
                  </div>
                </article>
              </Col>
            ))}
        </Row>
        {posts.length >= numPosts && (
          <div className="text-center my-5">
            <Button className="greenBtn" onClick={handleShowMore}>
              Voir plus
            </Button>
          </div>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default News;
