import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Navigation from "./components/navigation/Navigation";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Home from "./components/home/Home";
import News from "./components/news/News";
import Login from "./components/login/Login";
import Admin from "./components/login/Admin";
import Post from "./components/post/Post";

function App() {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" exact element={<Home />}></Route>
        <Route path="news" exact element={<News />}></Route>
        <Route path="login" exact element={<Login />}></Route>
        <Route path="Admin" exact element={<Admin />}></Route>
        <Route path="/post/:id" exact element={<Post />} />
      </Routes>
    </Router>
  );
}

export default App;
